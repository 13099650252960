import React from "react";
import {Link} from "react-router-dom"
import Header from "./header"
import Game from "./assets/findpair/game"


let game=new Game();

class FindPair extends React.Component{
    rows=4;
    columns=5;
    AssignImages(){
        let imgTags=document.querySelectorAll(".img-find-pair");
        let simages=game.ShuffleArrey([].concat(...game.images,...game.images))
        let x=0;
        for (let i = 0; i < imgTags.length; i++) {
            imgTags[i].src="/images/findpair/"+simages[x]+".png"
            x++;
            
        }
    }
    AddListeners(){
        for (let i = 0; i < game.cards.length; i++) {
            game.cards[i].addEventListener("click", function(){
                game.ShowCard(this);
            })
        }
    }
    init(){
        game.canPick=true;
        game.points=0;
        game.tries=0;
        game.firstOpenImage=undefined;
        game.secondOpenImage=undefined;
        this.AssignImages();
        document.querySelector("#find-pair-tries").innerHTML=game.tries;
        game.cards=document.querySelectorAll(".find-pair-card");
        for (let i = 0; i < game.cards.length; i++) {
            game.cards[i].childNodes[0].style.display="none";
            game.cards[i].childNodes[1].style.display="inline";
        }
    }


    addCards(){
        let body=[];
        for (let i = 0; i < this.rows; i++) {
            body.push(this.cardBody(i));
        }
        return body;
       
    }
    cardBody(key){
        return(
            <button className="find-pair-card" id="find-pair-1" key={key}>
                <img className="img-find-pair" src="/images/findpair/cardbackground.jpg" alt=""></img>
                <img className="" src="/images/findpair/cardbackground.jpg" alt=""></img>
            </button>
        )
    }
    addColumns(){
        let body=[];
        for (let i = 0; i < this.columns; i++) {
            body.push(this.columnBody(i));
            
        }
        return body;
    }
    columnBody(key){
        return(
            <div className="col-2" key={key}>
                {this.addCards()}
            </div>
        )
    }
    renderBody(){
        return(
            <div className="row mb-5 find-pair-columns">
                {this.addColumns()}
            </div>
            
        )
    }
    
   componentDidMount(){
       this.init();
       this.AddListeners();
   }
    render(){
        return(
            <div className="">
                <div className="wrapper">
                    <div className="container1">
                    <div className="float-right">
                            <Header/>
                        </div> 
                        <div className="row">
                            <div className="col-md-6">
                                <div className="text-left navbar-brand-container">
                                    <Link className="navbar-brand text-center" to="/">
                                        <p className="mb-0">- Full stack developer - </p>
                                        <p id="brand">Veljko Rankovic</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-6 mb-2 mt-2">
                                <button className="btn btn-success btn-sm" onClick={()=>{this.init()}}>Reset</button>
                                <div className="mt-2" >Number of tries: <span id="find-pair-tries">0</span></div>
                            </div>
                        </div>

                        <div className="find-pair-body">
                            {this.renderBody()}
                            
                        </div>
                    </div> 
                <footer className="text-right mt-4">
                        <p>&copy; 2019 Veljko Rankovic, all right reserved</p> 
                </footer>
            </div> 
        </div>
        )
    }
}

export default FindPair