import React from "react";

class Header extends React.Component{
    componentDidMount(){
        let theme=window.localStorage.getItem("theme")
        if(theme==="dark" || theme===null){
            document.querySelector(".dark-theme").classList.add("active-background")
        }
        else{
            document.querySelector(".light-theme").classList.add("active-background")
        }
    }
    darkTheme(){
        document.querySelector("body").style.backgroundImage="url('/css/pozadina.jpg')"
        document.querySelector("body").style.color="white"
        document.querySelector(".dark-theme").classList.add("active-background")
        document.querySelector(".light-theme").classList.remove("active-background")
        window.localStorage.setItem("theme", "dark")
    }
    lightTheme(){
        document.querySelector("body").style.backgroundImage="url('/css/pozadina3.jpg')"
        document.querySelector(".light-theme").classList.add("active-background")
        document.querySelector(".dark-theme").classList.remove("active-background")
        window.localStorage.setItem("theme", "light")
    }

    render(){
        return(
            <div>
                <div className="text-right mt-1" id="background-picker">
                    <img className="dark-theme m-0" src="/css/pozadina.jpg" alt="" onClick={this.darkTheme}></img>
                    <img className="light-theme m-0 ml-3" src="/css/pozadina3.jpg" alt="" onClick={this.lightTheme}></img>
                </div>
            </div>
        )
    }
}

export default Header;