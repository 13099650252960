export default class Snake {
    constructor(game){
        this.game=game      
        this.snakeHeadUp=document.querySelector("#snake-head-up")
        this.snakeHeadDown=document.querySelector("#snake-head-down")
        this.snakeHeadLeft=document.querySelector("#snake-head-left")
        this.snakeHeadRight=document.querySelector("#snake-head-right")
        this.snakeTailLeft=document.querySelector("#snake-tail-left")
        this.snakeTailRight=document.querySelector("#snake-tail-right")
        this.snakeTailUp=document.querySelector("#snake-tail-up")
        this.snakeTailDown=document.querySelector("#snake-tail-down")
        this.snakeBody=document.querySelector("#snake-body")
        this.gameWidth=game.gameWidth
        this.width=game.boxSize;
        this.height=game.boxSize;
        this.isGameOver=false
        this.baseSnakeTail=4
        this.snakeTail=this.baseSnakeTail*game.boxSize/this.game.speed
        this.position={
            x:game.boxSize*10,
            y:game.boxSize*16
        }
        this.snake=[]
        this.snakeHeadImage=""
        this.snakeTailImage=""
        this.speedX=0
        this.speedY=0
        this.up=true;
        this.left=false;
        this.right=false;
        this.down=false;
        this.allowUpOrDown=false
        this.allowNextKey=true
        this.allowLeftOrRight=false
        this.previouseKey="up"

        
    }
    moveLeft(){
        if(this.previouseKey==="right")return;
        if(this.allowNextKey){
            this.left=true;
            this.right=false;
            this.up=false;
            this.down=false;
            this.previouseKey="left"
            this.allowNextKey=false
        }
    }

    moveRight(){
        if(this.previouseKey==="left")return;
        if(this.allowNextKey){
            this.left=false;
            this.right=true;
            this.up=false;
            this.down=false;
            this.previouseKey="right"
            this.allowNextKey=false
        }
    }

    moveUp(){
        if(this.previouseKey==="down")return;
        if(this.allowNextKey){
            this.left=false;
            this.right=false;
            this.up=true;
            this.down=false;
            this.previouseKey="up"
            this.allowNextKey=false
        }
    }

    moveDown(){
        if(this.previouseKey==="up")return;
        if(this.allowNextKey){
            this.left=false;
            this.right=false;
            this.up=false;
            this.down=true;
            this.previouseKey="down"
            this.allowNextKey=false
        }
    }

    draw(ctx){
        let p={
            x:this.position.x,
            y:this.position.y,
            sizeX:this.width,
            sizeY:this.height
            }
        this.snake.push(p)
        if(this.snake.length>this.snakeTail){
            this.snake.shift()
        }
        if(this.game.speed===2){
            this.drawingSnakeParts(ctx,p,0,5,3)
        }
        if(this.game.speed===5){
            this.drawingSnakeParts(ctx,p,0,2,2)
        }
        if(this.game.speed===10){
            this.drawingSnakeParts(ctx,p,1,1,1)
        }
    }

    update(deltaTime){
        this.allowMovement();
        this.move();
        this.position.x+=this.speedX
        this.position.y+=this.speedY
        this.snakeTail=this.baseSnakeTail*this.game.boxSize/this.game.speed
        this.dettectEdgeOfMap();
        this.dettectCollision()
        this.checkForTailDirection()
        
    }

    allowMovement(){
        if(this.position.x%this.game.boxSize===0){
            this.allowUpOrDown=true
        }else{
            this.allowUpOrDown=false
        }
        if(this.position.y%this.game.boxSize===0){
            this.allowLeftOrRight=true
        }else{
            this.allowLeftOrRight=false
        }
    }

    move(){
        if(this.left&&this.allowLeftOrRight){
            this.snakeHeadImage=this.snakeHeadLeft
            this.speedX=-this.game.speed
            this.speedY=0
            this.allowNextKey=true
        }

        if(this.right &&this.allowLeftOrRight){
            this.speedX=this.game.speed
            this.snakeHeadImage=this.snakeHeadRight
            this.speedY=0
            this.allowNextKey=true
        }

        if(this.up && this.allowUpOrDown){
            this.snakeHeadImage=this.snakeHeadUp
            this.speedY=-this.game.speed
            this.speedX=0
            this.allowNextKey=true
        }
        if(this.down && this.allowUpOrDown){
            this.snakeHeadImage=this.snakeHeadDown
            this.speedY=this.game.speed
            this.speedX=0
            this.allowNextKey=true
        }
    }

    dettectEdgeOfMap(){
        if(this.position.x<0){
            this.position.x=this.game.gameWidth-this.game.boxSize
        }
        if(this.position.x+this.width>this.gameWidth){
            this.position.x=0
        }
        if(this.position.y<0){
            this.position.y=this.game.gameWidth-this.game.boxSize
        }
        if(this.position.y+this.width>this.gameWidth){
            this.position.y=0
        }
    }

    dettectCollision(){
        if(this.snake.length>2){
            let head=this.snake[this.snake.length-1]
            let body=this.snake.slice(0, this.snake.length-1)
            for (let e of body){
                if(head.x===e.x && head.y===e.y){
                    this.game.gamestate=3
                    break
                }
            }
        }
    }
    checkForTailDirection(){

        if(this.snake.length<2){this.snakeTailImage=this.snakeTailUp}
        else{
            if(this.snake[0].x<this.snake[1].x && this.snake[0].y===this.snake[1].y){
                this.snakeTailImage=this.snakeTailRight
            }
            if(this.snake[0].x>this.snake[1].x && this.snake[0].y===this.snake[1].y){
                this.snakeTailImage=this.snakeTailLeft
            }
            if(this.snake[0].y>this.snake[1].y && this.snake[0].x===this.snake[1].x){
                this.snakeTailImage=this.snakeTailUp
            }
            if(this.snake[0].y<this.snake[1].y && this.snake[0].x===this.snake[1].x){
                this.snakeTailImage=this.snakeTailDown
            }
        }
    }
    drawingSnakeParts(ctx,p,x, y, z){
        for(let i=0; i<this.snake.length-x; i+=y){
            let p=this.snake[i]
            if(i<z){}
            else{
                ctx.drawImage(this.snakeBody, p.x,p.y,p.sizeX, p.sizeY)
            }
        }
        ctx.drawImage(this.snakeHeadImage, p.x,p.y,p.sizeX, p.sizeY)
        ctx.drawImage(this.snakeTailImage, this.snake[0].x,this.snake[0].y,this.snake[0].sizeX, this.snake[0].sizeY)
    }
}

