import React from "react";
import {Link} from "react-router-dom";
import Header from "./header";

const calculator={
    displayValue:"0",
    firstOperand:null,
    waitingForSecondOperand:false,
    operator:null
};

function numberWithCommas(x){
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}
function updateDisplay(){
    const display=document.querySelector(".result")
    let data=numberWithCommas(calculator.displayValue)
    display.value=data
}

function detectKey(){
    document.addEventListener("keydown", event=>{
        if((event.keyCode>=96 && event.keyCode<=105) || (event.keyCode>=48 && event.keyCode<=57)){
            inputDigit(event.key)
            updateDisplay()
            clicked(parseFloat(event.key))
        }
        if(event.keyCode===110  || event.keyCode===190){
            inputDecimal(event.key)
            updateDisplay()
            clicked('.')
        }
        if(event.keyCode===13){
            let operator="="
            handleOperator(operator)
            updateDisplay()
            clicked(operator)
        }
        if(event.keyCode===107){
            let operator="+"
            handleOperator(operator)
            updateDisplay()
            clicked(operator)
        }
        if(event.keyCode===109){
            let operator="-"
            handleOperator(operator)
            updateDisplay()
            clicked(operator)
        }
        if(event.keyCode===106){
            let operator="*"
            handleOperator(operator)
            updateDisplay()
            clicked(operator)
        }
        if(event.keyCode===111){
            let operator="/"
            handleOperator(operator)
            updateDisplay()
            clicked(operator)
        }
        if(event.keyCode===8){
            let operator="removeLast"
            deleteLast()
            updateDisplay()
            clicked(operator)
        }
    })
}

function nesto(event){
    const target=event.target;
    if(!target.matches("button")){
        return;
    }if(target.classList.contains("removeLast")){
        deleteLast()
        updateDisplay()
        clicked("removeLast")
        return
    }
    if(target.classList.contains("operator") ){
        handleOperator(target.value)
        updateDisplay()
        clicked(target.value)
        return;
    }
    if(target.classList.contains("decimal")){
        inputDecimal(target.value)
        updateDisplay()
        clicked(target.value)
        return
    }
    if(target.classList.contains("all-clear")){
        resetCalculator()
        updateDisplay()
        clicked(target.value)
        return;
    }
    inputDigit(target.value)
    updateDisplay()
    clicked(target.value)
}

function inputDigit(digit){
    const {displayValue, waitingForSecondOperand}=calculator
    if(waitingForSecondOperand===true){
        calculator.displayValue=digit;
        calculator.waitingForSecondOperand=false
    }else{
        if(calculator.displayValue==="0" && digit==="00"){
            calculator.displayValue="0"
        }else{
            calculator.displayValue=displayValue==="0"? digit: displayValue+digit
        }
    }
}

function deleteLast(){
    const {displayValue, waitingForSecondOperand}=calculator
    if(waitingForSecondOperand===true){
        return;
    }
    else{
        calculator.displayValue=displayValue==="0"? "0":displayValue.substring(0,displayValue.length-1)
        if(displayValue.length===1){
            calculator.displayValue="0"
        }
    }
}

function inputDecimal(dot){
    if(calculator.waitingForSecondOperand===true)return;

    if(!calculator.displayValue.includes(dot)){
        calculator.displayValue+=dot
    }
}

function handleOperator(nextOperator){
    const {firstOperand, displayValue, operator}=calculator
    const inputValue=parseFloat(displayValue)

    if(operator && calculator.waitingForSecondOperand){
        calculator.operator=nextOperator;
        return
    }

    if(firstOperand===null){
        calculator.firstOperand=inputValue;
    }else if(operator){
        const currentValue=firstOperand || 0;
        const result=performCalculation[operator](currentValue, inputValue)
        calculator.displayValue=String(result)
        calculator.firstOperand=result
    }
    calculator.waitingForSecondOperand=true;
    calculator.operator=nextOperator
}

const performCalculation={
    "/":(firstOperand, secondOperand)=>firstOperand/secondOperand,
    "*":(firstOperand, secondOperand)=>firstOperand*secondOperand,
    "+":(firstOperand, secondOperand)=>firstOperand+secondOperand,
    "-":(firstOperand, secondOperand)=>firstOperand-secondOperand,
    "=":(firstOperand, secondOperand)=>secondOperand
}

function resetCalculator(){
    calculator.displayValue="0";
    calculator.firstOperand=null;
    calculator.waitingForSecondOperand=false;
    calculator.operator=null
}

function clicked(id){
    document.getElementById(id).classList.add("clicked")
    setTimeout(function(){
        document.getElementById(id).classList.remove("clicked");
    }, 100)
}






class Calculator extends React.Component{
    componentDidMount(){
        updateDisplay()
        detectKey()
    }
    render(){
        return(
            <div>
                <div className="wrapper">
                    <div className="container1">
                        <div className="row">
                            <div className="col-6">
                                <div className="text-left navbar-brand-container">
                                    <Link className="navbar-brand text-center" to="/">
                                        <p className="mb-0">- Full stack developer - </p>
                                        <p id="brand">Veljko Rankovic</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6">
                                <Header/>
                            </div> 
                        </div>
                        <div className="">
                                <h3 className="text-center">Calculator</h3>
                            <div className="row mt-5 mb-5">
                                <div className="col-lg-4 col-1 col-sm-2"></div>
                                <div className="col-lg-4 col-10 col-sm-8 text-center calculator ">
                                    <div>
                                        <input type="text" value="" disabled className="result"></input>
                                    </div>
                                    <div className="buttons">
                                        <div>
                                            <button id="CE" className="all-clear" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="CE">CE</button>
                                            <button id="C" className="all-clear" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="C">C</button>
                                            <button id="removeLast" className="removeLast" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="removeLast"><i className="fa fa-long-arrow-left"></i></button>
                                            <button id="/" className="operator" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="/">/</button>
                                        </div>
                                        <div>
                                            <button id="7" className="digit" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="7">7</button>
                                            <button id="8" className="digit" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="8">8</button>
                                            <button id="9" className="digit" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="9">9</button>
                                            <button id="*" className="operator" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="*">*</button>
                                        </div>
                                        <div>
                                            <button id="4" className="digit" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="4">4</button>
                                            <button id="5" className="digit" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="5">5</button>
                                            <button id="6" className="digit" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="6">6</button>
                                            <button id="-" className="operator" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="-">-</button>
                                        </div>
                                        <div>
                                            <button id="1" className="digit" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="1">1</button>
                                            <button id="2" className="digit" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="2">2</button>
                                            <button id="3" className="digit" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="3">3</button>
                                            <button id="+" className="operator" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="+">+</button>
                                        </div>
                                        <div>
                                            <button id="0" className="digit" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="0">0</button>
                                            <button id="00" className="digit" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="00">00</button>
                                            <button id="." className="decimal" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value=".">.</button>
                                            <button id="=" className="operator" onClick={(e)=>{clicked(e.target.id); nesto(e);}} value="=">=</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-1 col-sm-2"></div>
                            </div>
                        </div>  
                    </div>
                    <footer className="text-right">
                            <p>&copy; 2019 Veljko Rankovic, all right reserved</p> 
                    </footer>
                </div> 
            </div>
        )
    }
}

export default Calculator;