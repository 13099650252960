export default class Point {
    constructor(game){
        this.game=game
        this.fruit=document.querySelector("#img_fruit");
        this.gameWidth=game.gameWidth
        this.width=game.boxSize;
        this.height=game.boxSize;
        this.position={
            x:this.game.randomPosition(),
            y:this.game.randomPosition()
        }
    }

    draw(ctx){
        ctx.drawImage(this.fruit,this.position.x, this.position.y, this.width, this.height)
    }

    update(){
        let indexOfSnake=this.game.snake.snake.length-1
        let snake=this.game.snake.snake
        if(snake.length>0 && this.position.x===snake[indexOfSnake].x&& this.position.y===snake[indexOfSnake].y){
            this.position.x=this.game.randomPosition()
            this.position.y=this.game.randomPosition()
            this.game.snake.baseSnakeTail+=0.5
            if(this.game.speed===2){
                this.game.score+=1
            }if(this.game.speed===5){
                this.game.score+=2
            }if(this.game.speed===10){
                this.game.score+=3
            }
            
        }
        for(let i of snake){
            if(this.position.x===i.x&&this.position.y===i.y){
                this.position.x=this.game.randomPosition()
                this.position.y=this.game.randomPosition()
            }
        }
    }
}