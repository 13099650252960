

export default class Game{
    cards;
    firstOpenImage;
    secondOpenImage;
    canPick;
    points;
    tries;
    
    constructor(){
        this.numberOfPairs=10;
        this.images=["avatar0","avatar1","avatar2","avatar3","avatar4","avatar5","avatar6","avatar7","avatar8","avatar9"];
    }

    ShowCard(card){
        if(this.canPick){
            let img=card.childNodes[0];
            let backgroundImg=card.childNodes[1];
            if(img.style.display==="none"){
                img.style.display="inline";
                backgroundImg.style.display="none";
                if(this.firstOpenImage===undefined){
                    this.firstOpenImage=card;
                }
                else{
                    this.secondOpenImage=card;
                    this.canPick=false;
                    this.ChekIfTheSame();
                }
            }
        }
    }
    ShuffleArrey(a){
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }
    ChekIfTheSame(){
        if(this.firstOpenImage.childNodes[0].src===this.secondOpenImage.childNodes[0].src){
            this.firstOpenImage.childNodes[0].classList.remove("img-find-pair");
            this.secondOpenImage.childNodes[0].classList.remove("img-find-pair");
            this.points++;
            this.firstOpenImage=undefined;
            this.secondOpenImage=undefined;
            this.canPick=true;
            if(this.points===this.numberOfPairs){
                console.log("Game Won");
            }
    
        }
        else{
            setTimeout(()=>{
                this.firstOpenImage.childNodes[0].style.display="none";
                this.firstOpenImage.childNodes[1].style.display="inline";
                this.secondOpenImage.childNodes[0].style.display="none"
                this.secondOpenImage.childNodes[1].style.display="inline"
                this.firstOpenImage=undefined;
                this.secondOpenImage=undefined;
                this.canPick=true;
            },500)
        }
        this.tries++;
        document.querySelector("#find-pair-tries").innerHTML=this.tries;
    }
}