import React from "react";
import {Link} from "react-router-dom";
import Header from "./header"


class MainPage extends React.Component{    
    clock(){
        let interval=setInterval(()=>{
            let hours;
            let minutes;
            let seconds;
            let x=new Date();
            hours=x.getHours();
            minutes=x.getMinutes();
            seconds=x.getSeconds();
            if(hours<10)hours="0"+x.getHours();
            if(minutes<10)minutes="0"+x.getMinutes();
            if(seconds<10)seconds="0"+x.getSeconds();
            try{
                document.getElementById("clock").innerHTML= hours+":"+minutes+":"+seconds;
            }
            catch{
                clearInterval(interval);
            }
        },1000)
    }
    render(){
        this.clock();
        return(
            <div>                 
                <div className="container1">
                <div className="ml-4 text-right" id="clock">-</div>
                    <div className="row">
                        <div className="col-6">
                            <div className="text-left navbar-brand-container">
                                <Link className="navbar-brand text-center" to="/">
                                    <p className="mb-0">- Full stack developer - </p>
                                    <p id="brand">Veljko Rankovic</p>
                                </Link>
                            </div>
                        </div>
                        <div className="col-6">
                            
                        </div> 
                    </div>
                    <div className="container">
                        <div className="profile ">
                                <div className="row">
                                    <div className="col-md-4 text-center p-1 about-me-panel">
                                    <h4 className="mt-1 text-center">ABOUT ME:</h4>
                                        <div className="m-2 profile-info-panel">
                                            <div className="text-center">   
                                                <img src="images/me.jpg" width="80%" alt=""/>
                                            </div>
                                            <div className="m-2">
                                                <div className=" mt-4">
                                                    <h5>Name: Veljko Rankovic</h5>
                                                    <h5>Age: 27</h5>
                                                    <h5>Location: Serbia</h5>
                                                    <h6>Email: veljkorankovic@gmail.com</h6>
                                                    <div >
                                                        <a className="mr-2" href="https://www.linkedin.com/in/veljko-rankovic-5211b7196/" target="_blank" rel="noopener noreferrer" ><i className="fa fa-linkedin-square fa-2x"></i></a>
                                                        <a className="ml-2" href="https://github.com/Veljko777" target="_blank" rel="noopener noreferrer"><i className="fa fa-github fa-2x" ></i></a>
                                                    </div>
                                                    <p className="text-left">
                                                    Full stack developer. <br/>
                                                    Great knowledge and experience in HTML5, CSS, SASS JavaScript, C# React.js, Redux, Bootstrap, Unity,
                                                    Node.js, Express, MySQL and MongoDB. <br/>
                                                    Ready for new challenges and opportunities. Highly motivated for learning more with 
                                                    great research abilities. Dedicated and responsible for work.
                                                </p>
                                                </div>
                                                <br/>
                                                <div>
                                                    <h5 className="">Skills:</h5>
                                                    <div className="mr-4 ml-4">
                                                        <div>
                                                            <h6 className="text-center">JavaScript</h6>
                                                            <div className="status-bar">
                                                                <div className="progress-bar" style={{width:"95%"}}></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h6 className="text-center">C#</h6>
                                                            <div className="status-bar">
                                                                <div className="progress-bar" style={{width:"90%"}}></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h6 className="text-center">HTML5, CSS3, SASS Bootstrap</h6>
                                                            <div className="status-bar">
                                                                <div className="progress-bar" style={{width:"95%"}}></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h6 className="text-center">Unity</h6>
                                                            <div className="status-bar">
                                                                <div className="progress-bar" style={{width:"80%"}}></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h6 className="text-center">React.js, Redux.js</h6>
                                                            <div className="status-bar">
                                                                <div className="progress-bar" style={{width:"82%"}}></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h6 className="text-center">Node.js, Express</h6>
                                                            <div className="status-bar">
                                                                <div className="progress-bar" style={{width:"85%"}}></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h6 className="text-center">MySQL, MongoDB</h6>
                                                            <div className="status-bar">
                                                                <div className="progress-bar" style={{width:"73%"}}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ml-4 mb-5">
                                                    <h5 className="">Personal skills:</h5>
                                                    <p>-Fast learner</p>
                                                    <p>-Good researcher</p>
                                                    <p>-Complex problem solver</p>
                                                    <p>-Innovative</p>
                                                    <p>-Dedicated</p>
                                                    <p>-Motivated to learn more</p>
                                                </div>
                                                <h5 className="">Languages:</h5>
                                                <div className="ml-4 mr-4">
                                                    <h6 className="text-center">Serbian</h6>
                                                    <div className="status-bar">
                                                        <div className="progress-bar" style={{width:"100%"}}></div>
                                                    </div>
                                                </div >
                                                <div className="ml-4 mr-4">
                                                    <h6 className="text-center">English</h6>
                                                    <div className="status-bar">
                                                        <div className="progress-bar" style={{width:"90%"}}></div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <h5 className="mt-3">Education:</h5>
                                                <div className="text-left pb-3">
                                                    <p className="mb-3">
                                                        Second grade: Faculty of Technology, Arandjelovac, Serbia, <br/>
                                                        2018-present. <br/>Field of study: Information Technology
                                                    </p>
                                                    <p>
                                                        Associate’s Degree: Business School of Applied Studies, Novi <br/> Sad, Serbia, 2012-2016. <br/>
                                                        Field of study: Financial Business and accounting.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                <div className="col-md-8 p-1 my-work-panel">
                                    <div className="mr-4 mb-5">
                                        <div className="row">
                                             <div className="col-8">
                                                <h4 className="mt-1 ml-5">MY WORK:</h4>
                                             </div>
                                             <div className="col-4">
                                                <Header/>
                                             </div>
                                        </div>
                                       
                                       
                                        <div className="cards">
                                        <h5 className="text-center">Riddle Battle - Android game</h5>
                                            <div className="row">
                                                <div className="col-4">
                                                <a href="https://play.google.com/store/apps/details?id=com.BeBetterGames.RiddleBattle&hl=en_US" target="_blank" rel="noopener noreferrer">
                                                    <img src="images/Riddle Battle.png" alt=""/>
                                                </a>
                                                </div>
                                                <div className="col-8">
                                                    <div className="text-justify mt-2">
                                                        <p>Quizz game build with Unity.
                                                        </p>
                                                        <p>Download game <a href="https://play.google.com/store/apps/details?id=com.BeBetterGames.RiddleBattle&hl=en_US" target="_blank" rel="noopener noreferrer">here.</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cards">
                                        <h5 className="text-center">Kuca sljiva Podgorko - Web application</h5>
                                            <div className="row">
                                                <div className="col-4">
                                                    <a href="http://www.kucasljivapodgorko.rs/"target="_blank" rel="noopener noreferrer">
                                                        <img src="images/podgorko.jpg" alt=""/>
                                                    </a>
                                                </div>
                                                <div className="col-8">
                                                    <div className="text-justify mt-2">
                                                        <p>Complete functional webshop done with React, Redux and Node.js, with use of MySQL database. Fully responsive website.
                                                            <br/> 
                                                            Podgorko is shop with specific products. Podgorko is part of Serbian company Belfarma d.o.o.
                                                        </p>
                                                        <p>To visit website click on image or click <a href="http://www.kucasljivapodgorko.rs/" target="_blank" rel="noopener noreferrer">here.</a></p>
                                                        <p>To Github repo click <a href="https://github.com/Veljko777/Kuca-Sljiva-Podgorko" target="_blank" rel="noopener noreferrer">here.</a></p> 
                                                    </div>      
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cards">
                                        <h5 className="text-center">Eco Plastex - Web application</h5>
                                            <div className="row">
                                                <div className="col-4">
                                                    <a href="http://www.ecoplastex.rs/" target="_blank" rel="noopener noreferrer">
                                                        <img src="images/ecoplastex.png" alt=""/>
                                                    </a>
                                                </div>
                                                <div className="col-8">
                                                    <div className="text-justify mt-2">
                                                        <p>Complete functional website done with React, Redux and Node.js. Fully responsive website. <br/> Eco Plastex is a local Serbian company 
                                                            that produce paper bags, pizza boxes, printing logos on bags and boxes.
                                                        </p>
                                                        <p>To visit website click on image or click <a href="http://www.ecoplastex.rs/" target="_blank" rel="noopener noreferrer">here.</a></p>
                                                        <p>To Github repo click <a href="https://github.com/Veljko777/EcoPlastex" target="_blank" rel="noopener noreferrer">here.</a></p> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cards">
                                        <h5 className="text-center">PSD to HTML example</h5>
                                            <div className="row">
                                                <div className="col-4">
                                                    <a href="http://psdtohtml.veljkorankovic.com/"target="_blank" rel="noopener noreferrer">
                                                        <img src="images/psdtohtmlexample.jpg" alt=""/>
                                                    </a>
                                                </div>
                                                <div className="col-8">
                                                    <div className="text-justify mt-2">
                                                        <p>
                                                            Example of PSD to HTML with functionality with over 99.5% match. Javascript, SASS, HTML5.
                                                        </p>
                                                        <p>To visit website click on image or click <a href="http://psdtohtml.veljkorankovic.com/" target="_blank" rel="noopener noreferrer">here.</a></p>
                                                        <p>Original image <a href="images/PSD.jpg" target="_blank">here</a></p>
                                                    </div>    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cards">
                                        <h5 className="text-center">Good old Snake - game</h5>
                                            <div className="row">
                                                <div className="col-4">
                                                <Link to="apps/snake"><img className="app-img" src="images/snakegame.jpg" alt=""/></Link>
                                                </div>
                                                <div className="col-8">
                                                    <div className="text-justify mt-2">
                                                        <p>Small game project done completly with javascript.
                                                        </p>
                                                        <p>Let's grab some apples <Link to="apps/snake">Play</Link></p>
                                                    </div>     
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cards">
                                        <h5 className="text-center">Find Pair - memory game</h5>
                                            <div className="row">
                                                <div className="col-4">
                                                <Link to="findpair"><img className="app-img" src="images/find-pair-img.png" alt=""/></Link>
                                                </div>
                                                <div className="col-8">
                                                    <div className="text-justify mt-2">
                                                        <p>Small game project done completly with javascript.
                                                        </p>
                                                        <p>Let's grab some apples <Link to="findpair">Play</Link></p>
                                                    </div>     
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cards">
                                        <h5 className="text-center">Calculator - application</h5>
                                            <div className="row">
                                                <div className="col-4">
                                                    <Link to="apps/calculator"><img className="app-img" src="images/calculator.jpg" alt=""/></Link> 
                                                </div>
                                                <div className="col-8">
                                                    <div className="text-justify mt-2">
                                                        <p>Small application done with javascript.
                                                        </p>
                                                        <p>Use application <Link to="apps/calculator">Use</Link></p>
                                                    </div>     
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cards">
                                        <h5 className="text-center">Home Pets - mini social network</h5>
                                            <div className="row">
                                                <div className="col-4">
                                                    <Link to="#">
                                                        <img src="images/pups-1.jpg" alt=""/>
                                                    </Link>
                                                </div>
                                                <div className="col-8">
                                                    <div className="text-justify mt-2">
                                                        <p>Mini social network completely done with Node.js, express and MongoDB. Website with ability to sign/login, 
                                                            posting, commenting, editing, deleteing, like/dislike, filters, editing own profile.
                                                        </p>
                                                        <p>Website is not uploaded and its just for practise of Node.js</p>
                                                        <p>To Github repo click <a href="https://github.com/Veljko777/home-pets" target="_blank" rel="noopener noreferrer">here.</a></p> 
                                                    </div>     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <footer className="text-right">
                    <p>&copy; 2019 Veljko Rankovic, all right reserved</p> 
                </footer>
                <button id="scroll-to-the-top" onClick={()=>{window.scrollTo(0,0)}}><i className="fa fa-arrow-up"></i></button>
                </div> 
            </div>
        )
    }
}
export default MainPage;

        
