import React from "react";
import {Link} from "react-router-dom"
import Header from "./header"
import Game from "./assets/snake/game"

let gameBox=20
let size=18*gameBox
let ctx=""
let game=""
let easyBtn=""
let hardBtn=""
let impossibleBtn=""
let framepersecond=""
function init(){
    framepersecond=document.querySelector(".fps")
    let canvas=document.querySelector("#snake");
    easyBtn=document.querySelector("#easyMode");
    hardBtn=document.querySelector("#hardMode");
    impossibleBtn=document.querySelector("#impossibleMode");
    canvas.width=size
    canvas.height=size
    ctx=canvas.getContext("2d");
    game=new Game(gameBox)
    ctx.rect(0,0,game.gameWidth, game.gameHeight);
    ctx.fillStyle="rgba(0,0,0,0.5)"
    ctx.fill()
    ctx.font="30px Arial";
    ctx.fillStyle="white;"
    ctx.textAlign="center";
    ctx.fillText("Let's go!!!", game.gameWidth/2, game.gameHeight/2)
}

let lastTime=0
let every20thFrame=0

function gameLoop(timestamp){
    let fps=Math.floor(1000/(timestamp-lastTime))
    lastTime=timestamp
    if(every20thFrame===20){
        framepersecond.innerHTML=fps
        every20thFrame=0
    }

    if(game.gamestate===3){
        window.cancelAnimationFrame(gameLoop)
        if(game.speed===2){
            easyBtn.style="display:inline"
            hardBtn.style="display:inline"
            impossibleBtn.style="display:inline"
        }
        if(game.speed===5){
            easyBtn.style="display:inline"
            hardBtn.style="display:inline"
            impossibleBtn.style="display:inline"
        }
        if(game.speed===10){
            easyBtn.style="display:inline"
            hardBtn.style="display:inline"
            impossibleBtn.style="display:inline"
        }

    }else{
        let deltaTime=timestamp-lastTime
        lastTime=timestamp
        ctx.clearRect(0, 0, size, size)
        try{
            game.update(deltaTime);
            game.draw(ctx);
            requestAnimationFrame(gameLoop)
        }
        catch{}
    }
    every20thFrame+=1
}


class Snake extends React.Component{
    componentDidMount(){
        init()
    }
    startEasyMode(){
        if(game.gamestate===3){
            game=new Game(gameBox)
            requestAnimationFrame(gameLoop)
            game.gamestate=1
        }if(game.gamestate===2){
            requestAnimationFrame(gameLoop)
            game.gamestate=1
        }
        easyBtn.style="display:none"
        hardBtn.style="display:none"
        impossibleBtn.style="display:none"
    }
    startHardMode(){
        if(game.gamestate===3){
            game=new Game(gameBox)
            game.speed=5
            requestAnimationFrame(gameLoop)
            game.gamestate=1
        }if(game.gamestate===2){
            game.speed=5
            requestAnimationFrame(gameLoop)
            game.gamestate=1
        }
        easyBtn.style="display:none"
        hardBtn.style="display:none"
        impossibleBtn.style="display:none"
    }
    startImpossibleMode(){
        if(game.gamestate===3){
            game=new Game(gameBox)
            game.speed=10
            requestAnimationFrame(gameLoop)
            game.gamestate=1
        }if(game.gamestate===2){
            game.speed=10
            requestAnimationFrame(gameLoop)
            game.gamestate=1
        }
        easyBtn.style="display:none"
        hardBtn.style="display:none"
        impossibleBtn.style="display:none"
    }
    render(){
        return(
            <div className="snake-body">
            <div className="wrapper">
                <div className="container1">
                    <div className="row">
                            <div className="col-6">
                                <div className="text-left navbar-brand-container">
                                    <Link className="navbar-brand text-center" to="/">
                                        <p className="mb-0">- Full stack developer - </p>
                                        <p id="brand">Veljko Rankovic</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6">
                                <Header/>
                            </div> 
                        </div>
                    <div className="text-center snake-game">
                        <div className=" text-center mt-2"> 
                            <div className="row" id="snake-score">
                                <div className="col-6 text-right pr-0"><img className="mt-3" src="../images/fruit.png" alt=""/></div>
                                <div className="col-6 text-left"><p className="m-0" id="score">0</p></div>
                            </div>  
                        </div>
                        <div className="canvas-div">
                            <img className="img_snake" id="snake-body" src="../images/snake/body1.png" alt=""></img>
                            <img className="img_snake" id="snake-head-down" src="../images/snake/head down1.png" alt=""></img>
                            <img className="img_snake" id="snake-head-up" src="../images/snake/head up1.png" alt=""></img>
                            <img className="img_snake" id="snake-head-right" src="../images/snake/head right1.png" alt=""></img>
                            <img className="img_snake" id="snake-head-left" src="../images/snake/head left1.png" alt=""></img>
                            <img className="img_snake" id="snake-tail-up" src="../images/snake/tail up1.png" alt=""></img>
                            <img className="img_snake" id="snake-tail-down" src="../images/snake/tail down1.png" alt=""></img>
                            <img className="img_snake" id="snake-tail-left" src="../images/snake/tail left1.png" alt=""></img>
                            <img className="img_snake" id="snake-tail-right" src="../images/snake/tail right1.png" alt=""></img>
                            <img className="img_snake" id="img_fruit" src="../images/fruit.png" alt=""></img>
                            <img className="img_snake" src="../images/background.jpg" alt=""></img>
                            <canvas className="" id="snake" alt=""></canvas>
                        </div>
                        
                        <div>
                            <div className="row">
                                <div className="col-6 text-right m-0 p-0"><p className="m-0 p-0">FPS:</p></div>
                                <div className="col-6 text-left m-0 p-0 pl-2"><p className="fps m-0 p-0">0</p></div>
                            </div>
                            <div className="pb-2 text-center">
                                <button className="btn btn-success mb-1 mr-2" id="easyMode" onClick={this.startEasyMode}>Easy <img className="snake-button-img m-0 p-0 mb-1" src="../images/fruit.png" alt=""></img>x1</button>
                                <button className="btn btn-warning mb-1 mr-2" id="hardMode" onClick={this.startHardMode}>Hard <img className="snake-button-img m-0 p-0 mb-1" src="../images/fruit.png" alt=""></img>x2</button>
                                <button className="btn btn-danger mb-1 " id="impossibleMode" onClick={this.startImpossibleMode}>Expert <img className="snake-button-img m-0 p-0 mb-1" src="../images/fruit.png" alt=""></img>x3</button>
                            </div>
                            
                        </div>
                    </div>
                
            </div> 
            <footer className="text-right">
                    <p>&copy; 2019 Veljko Rankovic, all right reserved</p> 
            </footer>
            </div> 
        </div>
        )
    }
}

export default Snake