import Snake from "./snake"
import InputHandlers from "./inputHandlers"
import Point from "./point"

const GAMESTATE={
    PAUSED:0,
    RUNNING:1,
    MENU:2,
    GAMEOVER:3,
}

export default class Game{
    constructor(size) {
        this.boxSize=size
        this.gameWidth=this.boxSize*18;
        this.gameHeight=this.boxSize*18;  
        this.speed=2
        this.gamestate=GAMESTATE.MENU;
        this.snake=new Snake(this)
        this.point=new Point(this)
        this.score=0
        this.gameObjects=[
            this.point,
            this.snake
        ]
        new InputHandlers(this.snake, this);
    }

    update(deltaTime){
        this.gameObjects.forEach((object)=> object.update(deltaTime))
        let showScore=document.querySelector("#score")
        showScore.innerHTML=this.score

    }
    draw(ctx){
        if(this.gamestate===3){
            ctx.rect(0,0,this.gameWidth, this.gameHeight);
            ctx.fillStyle="rgba(0,0,0,0.5)"
            ctx.fill()
            ctx.font="30px Arial";
            ctx.fillStyle="white;"
            ctx.textAlign="center";
            ctx.fillText("Game over", this.gameWidth/2, this.gameHeight/2)
        }
        if(this.gamestate===1){
            this.gameObjects.forEach((object)=> object.draw(ctx))
        }
    }

    randomPosition(){
        let worldboxes=[0]
        let box=0
        for (let i=1;i<this.gameWidth/this.boxSize;i++){
        box+=this.boxSize
        worldboxes.push(box)
        }
        return worldboxes[Math.floor(Math.random()*(worldboxes.length))]
    }
}