export default class InputHandler{
    constructor(snake, game){
        this.xDown=null;
        this.yDown=null;
        document.addEventListener("keydown", (event)=>{
            event.preventDefault()
            if(event.keyCode===37){
                    snake.moveLeft();
            }
            if(event.keyCode===39){
                snake.moveRight();
            }
            if(event.keyCode===38){
                snake.moveUp();
            }
            if(event.keyCode===40){
                snake.moveDown();
            }

            })        
            document.addEventListener('touchstart', (evt)=>{
                let firstTouch = evt.touches[0] ||             
                evt.originalEvent.touches[0];                                      
                this.xDown = firstTouch.clientX;                                      
                this.yDown = firstTouch.clientY; 
            }, false);
            document.addEventListener('touchend', (evt)=>{
                if ( ! this.xDown || ! this.yDown ) {
                    return;
                }
                let xUp = evt.changedTouches[0].clientX;                                  
                let yUp = evt.changedTouches[0].clientY;

                let xDiff = this.xDown - xUp;
                let yDiff = this.yDown - yUp;

                if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
                    if ( xDiff > 0) {
                        snake.moveLeft()
                    } if(xDiff<0) {
                        snake.moveRight()
                    }                       
                } else {
                    if ( yDiff > 0) {
                        snake.moveUp()
                    } if(yDiff<0) { 
                        snake.moveDown()
                    }                                                                 
                }
                this.xDown = null;
                this.yDown = null;    
            }, false); 

    }
}