import React from "react";
import {Router, Route, Switch} from "react-router-dom";
import history from "../history";

import MainPage from "./pages/MainPage"
import calculator from "./pages/calculator"
import Snake from "./pages/snake";
import findpair from"./pages/findpair";

import CreateAccount from "./pages/CreateAccount";

class App extends React.Component{
    render(){
        return (
            <div>
                <Router history={history}>
                    <Switch>
                        <Route path="/" exact component={MainPage}/>
                        <Route path="/apps/calculator" exact component={calculator}/>
                        <Route path="/apps/snake" exact component={Snake}/>
                        <Route path="/createaccount" exact component={CreateAccount} />
                        <Route path="/findpair" exact component={findpair} />
                    </Switch>
                </Router>
            </div>
        )
    }
}

export default App;