import React from "react";

class CreateAccount extends React.Component{
    render(){
        return(
            <div>Create account</div>
        )
    }
}

export default CreateAccount;